import { createRouter, createWebHistory } from 'vue-router';
import { AuthLayout, BaseLayout } from '@/layouts';

const routes = [
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'Login',
        path: 'login',
        component: () => import('@/pages/auth/Login.vue'),
        meta: { middleware: 'guest' },
      },
      {
        path: '/auth/:pathMatch(.*)*',
        redirect: '/auth/login',
      },
    ],
  },
  {
    path: '/fip',
    component: BaseLayout,
    redirect: () => {
      return { name: 'Overview' }
    },
    children: [
      {
        name: 'Overview',
        path: 'overview',
        component: () => import('@/pages/fip/overview/Overview.vue'),
        meta: { middleware: 'auth' },
      },
      {
        name: 'Benchmarking',
        path: 'benchmarking/:tab?',
        component: () => import('@/pages/fip/benchmarking/Benchmarking.vue'),
        meta: { middleware: 'auth' },
      },
      {
        name: 'History',
        path: 'history/:id?',
        component: () => import('@/pages/fip/history/History.vue'),
        meta: { middleware: 'auth' },
      },
      {
        name: 'Voyage',
        path: 'voyage/:id/:tab?/:filter?',
        component: () => import('@/pages/fip/voyage/Voyage.vue'),
        meta: { middleware: 'auth' },
      },
      {
        name: 'Chart',
        path: 'chart',
        component: () => import('@/pages/fip/chart/Chart.vue'),
        meta: { middleware: 'auth' },
      },
      {
        name: 'Fleet',
        path: 'fleet/:imo?/:tab?/:filter?/:id?',
        component: () => import('@/pages/fip/fleet/Fleet.vue'),
        meta: { middleware: 'auth' },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
